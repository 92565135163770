import React from 'react';
import PropTypes from 'prop-types';
import Form from './form.js';
import { connect } from 'react-redux';
import { setClubInfo, resetMemberInfo } from 'js/reducers/bgClub.js';
import style from './style.css';
import ErrorMessage from 'js/components/ErrorMessage';
import logOutIfLoggedIn from 'js/helpers/logOutIfLoggedIn';
import format from 'date-fns/format';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';

class PickClub extends React.Component {
    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        clubAccessId: PropTypes.string,
        setClubInfo: PropTypes.func,
        resetMemberInfo: PropTypes.func,
        isMemberAreaOpen: PropTypes.bool,
        memberSurveyStartDate: PropTypes.string,
        memberSurveyEndDate: PropTypes.string,
    };

    componentDidMount() {
        this.props.resetMemberInfo();
    }

    onSubmit = async clubInfo => {
        this.props.setClubInfo(clubInfo);
        this.props.history.push(`/member${this.props.location.search}`);
    };

    render() {
        const { isMemberAreaOpen, memberSurveyStartDate, memberSurveyEndDate } = this.props;

        if (!isMemberAreaOpen) {
            return (
                <ErrorMessage>
                    <div>
                        The Member area is not currently open. Member access is from{' '}
                        <b>
                            {format(memberSurveyStartDate, 'MMMM, D')} - {format(memberSurveyEndDate, 'MMMM, D')}
                        </b>
                    </div>
                </ErrorMessage>
            );
        }

        return (
            <div>
                <div className={style.title3}>NYOI Survey</div>
                <div className={style.title4}>Welcome, Club Organizer!</div>
                <div className={style.block}>
                    Use this website to access the NYOI 2025 National Youth (ages 9-12) and Teen (ages 13-18) Survey.
                </div>
                <Form onSubmit={this.onSubmit} initialValue={this.props.clubAccessId || ''} />
                <div style={{ marginTop: '2rem' }}>
                    Forgot your Club Access Code? <Link to="/adminCenter">Log In</Link> to the Admin Access Center to
                    find the Access Code for your Club.
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const allowMemberAnyway = /allow=true/.test(props.location.search);

    return {
        clubAccessId: state.app.bgClub.clubAccessId,
        isMemberAreaOpen: _get(state, 'app.params.data.memberSurveyOn') || allowMemberAnyway,
        memberSurveyStartDate: _get(state, 'app.params.data.dates.memberSurveyStartDate'),
        memberSurveyEndDate: _get(state, 'app.params.data.dates.memberSurveyEndDate'),
    };
};

export default logOutIfLoggedIn()(connect(mapStateToProps, { setClubInfo, resetMemberInfo })(PickClub));
