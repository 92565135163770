import React from 'react';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import _get from 'lodash/get';

const Boilerplate = window.cccisd.boilerplate;
const isLoggedIn = !!_get(window, 'cccisd.fortress.user.user');

export default () => WrappedComponent => {
    class Wrapper extends React.Component {
        componentDidMount = async () => {
            if (isLoggedIn) {
                await axios.get(Boilerplate.route('api.nexus.logout'));
                window.location.reload();
            }
        };

        render() {
            if (isLoggedIn) {
                return <Loader loading />;
            }

            return <WrappedComponent {...this.props} />;
        }
    }

    return Wrapper;
};
